import { JobApplication } from "../../../../core/models/jobs/job-application.model";
import { UrlParam } from "../../../../core/models/urlParam.model";
import { BaseService } from "../../../../core/services/base.service";
import { JobContent, JobResponse } from "../models";
import { JobMetrics } from "../models/jobMetrics.model";

export class JobService {
    static async GetJobs(category: string, subcategory: string, page: number): Promise<JobResponse> {
        try {
            const url = BaseService.BuildUrl(
                "/public/jobs",
                new UrlParam("category", category),
                new UrlParam("subCategory", subcategory),
                new UrlParam("page", page.toString()),
                new UrlParam("size", process.env.REACT_APP_PAGINATION_SIZE!),
            )

            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            if (response.status === 204) {
                throw new Error(`SHOW: No jobs to show.`);
            }

            let json = await response.json()

            return json as JobResponse;
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    static async GetJob(id: string): Promise<JobContent> {
        try {
            const url = BaseService.BuildUrl(`/public/jobs/${id}`)

            const response = await fetch(url);

            if (!response.ok) {
                if ([401, 404].includes(response.status)) {
                    throw new Error(`SHOW: El id ingresado no es válido.`);
                }

                throw new Error(`Error: ${response.status}`);
            }

            let json = await response.json()

            return json as JobContent;
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    static async GetMetrics(id: string): Promise<JobMetrics> {
        try {
            const url = BaseService.BuildUrl(`/public/jobs/${id}/metrics`)

            const response = await fetch(url);

            if (!response.ok) {
                if ([401, 404].includes(response.status)) {
                    throw new Error(`SHOW: El id ingresado no es válido.`);
                }

                throw new Error(`Error: ${response.status}`);
            }

            let json = await response.json()

            return json as JobMetrics;
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    static async AddView(id: string): Promise<number> {
        try {
            const url = BaseService.BuildUrl(`/public/jobs/${id}/views`)

            const response = await fetch(url, {
                method: "POST"
            });

            if (!response.ok) {
                if ([401, 404].includes(response.status)) {
                    throw new Error(`SHOW: El id ingresado no es válido.`);
                }

                throw new Error(`Error: ${response.status}`);
            }

            let json = await response.json()

            return json as number;
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    static async AddLike(id: string): Promise<number> {
        try {
            const url = BaseService.BuildUrl(`/public/jobs/${id}/likes`)

            const response = await fetch(url, {
                method: "POST"
            });

            if (!response.ok) {
                if ([401, 404].includes(response.status)) {
                    throw new Error(`SHOW: El id ingresado no es válido.`);
                }

                throw new Error(`Error: ${response.status}`);
            }

            let json = await response.json()

            return json as number;
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    static async ValidateDisable(id: string): Promise<boolean> {
        try {
            const url = BaseService.BuildUrl(`/public/jobs/${id}/likes/disable`)

            const response = await fetch(url, {
                method: "GET"
            });

            if (!response.ok) {
                if ([401, 404].includes(response.status)) {
                    throw new Error(`SHOW: El id ingresado no es válido.`);
                }

                throw new Error(`Error: ${response.status}`);
            }

            let json = await response.json();

            return json as boolean;
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    static async EasyApply(id: string, application: JobApplication, file: File): Promise<JobApplication> {
        try {
            const url = BaseService.BuildUrl(`/public/jobs/${id}/easy-apply`)

            const formData = new FormData();
            formData.append("file", file);

            const request = JSON.stringify({
                firstname: application.firstname,
                lastname: application.lastname,
                email: application.email,
                phoneNumber: application.phoneNumber,
                websiteURL: application.websiteURL,
                whatsappNotification: application.whatsappNotifiable,
                emailNotification: application.emailNotifiable,
            }); 

            formData.append("request", request);

            const response = await fetch( url,
                {
                    method: "POST",
                    body: formData,
                }
            );

            let data = await response.json();

            if (!response.ok) {
                if ([401, 404].includes(response.status)) {
                    throw new Error(`SHOW: El id ingresado no es válido.`);
                }

                if(data.message.includes("com.josalero.posting.core.exception.ResourceAlreadyExistException")){
                    const match = data.message.match(/Candidate .*?position/);

                    throw new Error(`${match}.`);
                }

                throw new Error(`${response.status} - ${data.message}`);
            }

            return data;
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
}