import Application from '../components/application/application';
import "react-phone-number-input/style.css";
import React, { useState } from 'react';
import { JobContent } from '../models';
import ReactGA from 'react-ga4';

interface props {
    job: JobContent
}

const Component: React.FC<props> = ({ job }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleLearnMore = () => {
        const label = job.category + " : " + job.subCategory + " : " + job.name;
        trackEvent("Learn More", label);
        window.open(`/#/jobs/${job.id}`, '_blank');
    };

    const trackEvent = (category = "", label = "") => {
        const action = "Clicked on " + label;

        const eventTracker = () => {
            console.log('Called');
            ReactGA.event({ category, action, label });
            console.log('Called');
        }

        return eventTracker;
    }

    const handleJobClick = () => {
        const label = job.category + " : " + job.subCategory + " : " + job.name;
        trackEvent("Apply Job", label);

        if (!job.featured) {
            setTimeout(() => {
                window.open(job.shortURL.longUrl, '_blank');
            }, 1000);

            return;
        }

        setIsOpen(true);
    }

    const htmlToPlainText = (html: string): string => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        doc.body.querySelectorAll('br').forEach((br) => {
            br.replaceWith('\n');
        });

        const text = doc.body.textContent?.replace(/\n{2,}/g, '\n').trim() || '';

        return text.length > 130 ? `${text.slice(0, 130)}...` : text;
    };

    return (
        <div className="bg-white shadow-lg rounded-lg h-full flex flex-col justify-between w-full">
            <div>
                <div className="bg-blue-500 text-white font-bold text-lg rounded-t-lg p-2 flex items-center justify-center h-20 ">
                    <span className="block w-full text-center">
                        {job.name.length > 30 ? `${job.name.slice(0, 30)}...` : job.name}
                    </span>
                </div>

                <div className="px-6 pt-4">
                    <span className="font-bold mb-2">Company</span>
                    <span className="ms-2">
                        {job.company}
                    </span>
                </div>
                <div className="px-6 pt-2">
                    <span className="font-bold mb-2">Posted On:</span>
                    <span className="ms-2">
                        {job.postedDate}
                    </span>
                </div>
                <div className="px-6 pt-2">
                    <p className="font-bold mb-2">Description</p>
                    <p className="min-h-44 xs:min-h-64 sm:min-h-32 lg:min-h-42 xl:min-h-40 2xl:min-h-40">
                        {job.featured ? (
                            htmlToPlainText(job.description)
                            .split('\n') 
                            .map((line, index) => (
                                <span key={index}>
                                    {line}
                                    <br />
                                </span>
                            ))
                        ) : (job.description.length > 130 ? `${job.description.slice(0, 130)}...` : job.description)}
                    </p>
                </div>
            </div>
            
            <div className="px-6 pb-4 block md:flex align-middle justify-end">
                <button
                    className={`h-10 ${!job.featured ? 'bg-blue-500 hover:bg-blue-600' : 'bg-green-500 hover:bg-green-600'} text-white font-bold py-2 mt-4 w-full rounded  active:scale-95 hover:scale-105`}
                    onClick={handleJobClick}
                >
                    {job.featured ? "Easy Apply" : "Apply now"}
                </button>

                <button
                    className=" h-10 bg-blue-500 text-white font-bold py-2 mt-4 w-full rounded hover:bg-blue-600 active:scale-95 hover:scale-105 md:ms-2"
                    onClick={handleLearnMore}
                >
                    Learn More
                </button>
            </div>

            <Application isOpen={isOpen} job={job} onClose={() => setIsOpen(false)} />
        </div>
    );
};

export default Component;
